<template>
  <v-card class="mt-1">
    <v-card-text>
      <directory-search @select="(person) => select(person)"></directory-search>
    </v-card-text>
    <card-single v-if="selected && '_id' in selected" :person="selected"></card-single>
    <v-sheet v-if="selected.person === 'Faculty'" style="height:825px;margin-top:1.2em;overflow:hidden">
      <v-calendar :events="classes" :start="today" type="day" first-time="07:00">
        <template v-slot:day-label-header="{ date }">
          <v-toolbar dense elevation="1">
            <v-btn small icon @click="prevDay()">
              <v-icon>fal fa-chevron-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-menu v-model="datePickerMenu" left close-on-content-click offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <h4 style="text-align: center">Class Schedule</h4>
                  <h5 style="text-align:center">{{ dateStr }}</h5>
                </div>
              </template>
              <v-date-picker v-model="pickerDate" no-title scrollable></v-date-picker>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn small icon @click="nextDay()">
              <v-icon>fal fa-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
      </v-calendar>
    </v-sheet>
  </v-card>
</template>
<style scoped>
.v-calendar >>> .v-calendar-daily__scroll-area {
  overflow: hidden;
}
.v-calendar >>> .v-calendar-daily_head-weekday {
  display: none;
}
</style>
<script>
import { computed, ref, watch } from '@vue/composition-api'
// import { $feathers } from '../../storeLocal/feathers'
import { stringFormatDate } from '../../helpers/formatters'
export default {
  components: {
    directorySearch: () => import('../greatscots/searchField'),
    cardSingle: () => import('../greatscots/CardSingle')
  },
  setup (props, { root }) {
    const selected = ref({})
    const classes = ref([])
    const dt = new Date()
    let termEnding = ''
    if (dt.getMonth() < 5) termEnding += '05'
    else if (dt.getMonth() === 5) termEnding += '10'
    else termEnding += '60'
    const term = dt.getFullYear() + termEnding

    function select ({ value: id }) {
      if (id == null || id === '') return
      root.$feathers.service('directory/people').get(id).then((person) => {
        selected.value = person
        loadClasses()
      })
    }

    const days = ['U', 'M', 'T', 'W', 'R', 'F', 'S']
    function loadClasses () {
      classes.value = []
      if ('pidm' in selected.value && 'person' in selected.value && selected.value.person === 'Faculty') {
        let start = new Date(pickerDate.value)
        start.setHours(start.getHours() + 6)
        start.setMinutes(0)
        const dow = days[start.getDay()]
        let end = new Date(start)
        end.setHours(end.getHours() + 18)
        root.$feathers.service('calendar/classes').find({ query: { term, 'instructors.pidm': selected.value.pidm, meetings: { $elemMatch: { start: { $gt: start }, end: { $lt: end } } } } }).then(({ data }) => {
          for (let i = 0; i < data.length; i++) {
            if ('meetingBase' in data[i] && Array.isArray(data[i].meetingBase) && data[i].meetingBase.length > 0) {
              for (let j = 0; j < data[i].meetingBase.length; j++) {
                const { room, days, startTime, endTime } = data[i].meetingBase[j]
                if (days.indexOf(dow) < 0) continue

                let [startHour, startMin] = startTime.split(':')
                let startAm = startMin.substr(2) === 'am'
                startMin = startMin.substr(0, 2)
                if (!startAm && startHour !== '12') startHour = parseInt(startHour) + 12
                else if (parseInt(startHour) < 10) startHour = '0' + startHour

                let [endHour, endMin] = endTime.split(':')
                let endAm = endMin.substr(2) === 'am'
                endMin = endMin.substr(0, 2)
                if (!endAm && endHour !== '12') endHour = parseInt(endHour) + 12
                else if (parseInt(endHour) < 10) endHour = '0' + endHour

                classes.value.push({
                  name: data[i].title + ' (' + (room || 'room not assigned') + ')',
                  start: today.value + ' ' + startHour + ':' + startMin,
                  end: today.value + ' ' + endHour + ':' + endMin
                })
              }
            }
          }
        })
      }
    }

    const today = ref(new Date().toISOString().substr(0, 10))

    function prevDay () {
      let dt = new Date(pickerDate.value)
      dt.setDate(dt.getDate() - 1)
      pickerDate.value = dt.toISOString().substr(0, 10)
    }
    function nextDay () {
      let dt = new Date(pickerDate.value)
      dt.setDate(dt.getDate() + 1)
      pickerDate.value = dt.toISOString().substr(0, 10)
    }

    const datePickerMenu = ref(false)
    dt.setHours(12)
    const pickerDate = ref(dt.toISOString().substr(0, 10))
    const dateStr = computed(() => {
      let dt = new Date(pickerDate.value)
      dt.setHours(dt.getHours() + 12)
      return stringFormatDate(dt).substr(0, 17)
    })
    watch(pickerDate, () => {
      let dt = new Date(pickerDate.value)
      dt.setHours(12)
      // dateStr.value = stringFormatDate(dt).substr(0, 17)
      loadClasses()
    })

    return {
      selected,
      classes,
      select,
      today,
      pickerDate,
      dateStr,
      prevDay,
      nextDay,
      datePickerMenu,
      stringFormatDate
    }
  }
}
</script>
